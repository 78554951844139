import React from "react";
import { createStore } from "../state/appStore";
import withAuthentication from "../components/Session/withAuthentication";
import { navigate } from "@reach/router";
import * as routes from "../constants/routes";
import { PropTypes } from "prop-types";
import { resetState, fetchInitialData } from "../state/actions";
import { PURGE } from "redux-persist";

const Purge = (_, context) => {
  /* 
    In case you're wondering, this is a way to reload the data in case you're, for example, changing the
    login state manually via localStorage.
  */
  const { store, persistor } = createStore();

  persistor
    .purge()
    .then(() => store.dispatch(resetState()))
    .then(_ =>
      store.dispatch(fetchInitialData({ loggedIn: context.loggedIn }))
    ); // TODO: syncStore

  return (
    <div>
      <span>...</span>
      navigate(routes.LANDING)
    </div>
  );
};

Purge.contextTypes = {
  loggedIn: PropTypes.bool
};

export default withAuthentication(Purge);
